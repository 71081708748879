<div class="container">
  <div class="modal">
    <dynamic-logo />
    <div class="modal-content">
      <router-outlet></router-outlet>

      <div>
        <ng-container *ngIf="isSuccess; else failed"> {{ successText }} </ng-container>

        <ng-template #failed>
          <span style="display: flex; align-items: center">
            Something went wrong, please try again or contact us if you need help
            <a href="http://wa.link/g2nnnq" target="_blank">
              <img src="assets/WhatsApp.svg" alt="contact us on whatsapp" width="100px" />
            </a>
          </span>
        </ng-template>
      </div>

      <mat-divider style="margin: 20px 0"></mat-divider>

      <button mat-button mat-raised-button color="primary" routerLink="/app/sources" style="width: 100%">
        Continue to {{ logoService.hostText }}
      </button>
    </div>
  </div>
</div>
