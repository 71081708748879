import {YelpConfigFollowUp} from "../classes-helpers/yelpConfig";

export enum LeadStatus {
  Answered = "answered",
  AutoFollowUp = "autoFollowUp",
  ScheduledMessage = "scheduledMessage",
  FollowUp = "followUp",
  NoAnswer = "noAnswer",
  Meeting = "meeting",
  Done = "done"
}

export type LeadStatusKeys = keyof typeof LeadStatus;
export type LeadStatusValues = `${LeadStatus}`;

export interface LeadScheduledMessage extends YelpConfigFollowUp {
  createdAt: Date;
}
