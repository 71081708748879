<h2 mat-dialog-title>Multiple Scheduled Messages</h2>
<mat-dialog-content>
  <form [formGroup]="settingsForm">
    <div formArrayName="scheduledMessages">
      <div class="header">
        <div class="add-button-container">
          <button class="add-icon" (click)="addMessage()" mat-mini-fab color="primary">
            <mat-icon>add</mat-icon>
          </button>
        </div>
        <mat-form-field appearance="fill" class="full-width search-field">
          <mat-label>Search messages</mat-label>
          <input matInput (keyup)="applyFilter($event)" />
        </mat-form-field>
      </div>
      <div class="messages-list">
        <mat-accordion class="example-headers-align" multi>
          <mat-expansion-panel *ngFor="let message of filteredMessages; let i = index" [formGroupName]="message.index">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <button
                  class="select-message-button"
                  mat-mini-fab
                  color="primary"
                  matTooltip="Start Scheduled Messages"
                  (click)="selectMessage(i); $event.stopPropagation()">
                  <mat-icon>touch_app</mat-icon>
                </button>
              </mat-panel-title>
              <mat-panel-description> {{ getMessageDisplayName(message) }} </mat-panel-description>
            </mat-expansion-panel-header>
            <mat-form-field appearance="fill" class="full-width">
              <mat-label>Name</mat-label>
              <input matInput [formControlName]="'title'" />
            </mat-form-field>
            <app-operation-config-follow-ups
              *ngIf="!isLoading"
              [inputConfig]="message.config"
              [inputGlobalConfig]="scheduledMessages.value"
              [isGlobalConfig]="false">
            </app-operation-config-follow-ups>
            <mat-action-row>
              <button
                mat-mini-fab
                color="warn"
                matTooltip="Delete message"
                class="delete-button"
                (click)="removeMessage(message.index)"
                [disabled]="scheduledMessages.length === 1">
                <mat-icon>delete</mat-icon>
              </button>
            </mat-action-row>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
      <div class="no-messages" *ngIf="filteredMessages.length === 0">
        No messages found. Try a different search or add a new message.
      </div>
    </div>
  </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button (click)="onCancel()" [disabled]="isLoading">Cancel</button>
  <button mat-raised-button color="primary" (click)="saveSettings()" [disabled]="settingsForm.invalid || isLoading">
    Save
  </button>
</mat-dialog-actions>
<mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
