import {Component, Inject} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {LeadsService} from "src/app/Services/leads.service";
import {
  YelpConfigFollowUp,
  YelpConfigFollowUpPredefinedMessages,
  YelpConfigFollowUpPredefinedMessagesType
} from "../../../../../server/src/db/classes-helpers/yelpConfig";
import {Lead} from "../../../../../server/src/db/classes/lead";

@Component({
  selector: "app-user-send-scheduled-message-dialog",
  templateUrl: "./user-send-scheduled-message-dialog.component.html",
  styleUrl: "./user-send-scheduled-message-dialog.component.scss"
})
export class UserSendScheduledMessageDialogComponent {
  public isSending = false;
  public scheduledMessagePredefinedMessages: YelpConfigFollowUpPredefinedMessages = {
    text: "",
    minutes: 60,
    displayValue: 60,
    type: YelpConfigFollowUpPredefinedMessagesType.Minutes
  };

  public scheduledMessage: YelpConfigFollowUp = {
    predefinedMessages: [this.scheduledMessagePredefinedMessages]
  };

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      message: string;
      lead: Lead;
    },
    public dialogRef: MatDialogRef<UserSendScheduledMessageDialogComponent>,
    private leadService: LeadsService
  ) {
    this.scheduledMessage.predefinedMessages[0].text = this.data.message;
  }

  public onCancel(): void {
    this.dialogRef.close(false);
  }

  public async sendMessage(): Promise<void> {
    this.isSending = true;
    if (this.scheduledMessage.predefinedMessages[0].text.length < 2) {
      return;
    }

    await this.leadService.setScheduledMessage(this.data.lead, this.scheduledMessage);
    this.isSending = false;
    this.dialogRef.close(true);
  }
}
