import {Pipe, PipeTransform} from "@angular/core";
import {BotMessage, Lead} from "../../../../server/src/db/classes/lead";
import {LeadStatus} from "../../../../server/src/db/classes/lead.types";
import {YelpConfigFollowUp} from "../../../../server/src/db/classes-helpers/yelpConfig";

@Pipe({name: "leadMessagesPipe"})
export class LeadMessagesPipe implements PipeTransform {
  transform<T = any[]>(lead: Lead) {
    const combinedMessages = [...lead.messages];

    if (lead.status === LeadStatus.ScheduledMessage && lead.scheduledMessage) {
      const scheduledMessage: YelpConfigFollowUp = lead.scheduledMessage;

      if (scheduledMessage.predefinedMessages && scheduledMessage.predefinedMessages.length > 0) {
        const firstMessage = scheduledMessage.predefinedMessages[0];

        // Calculate future time based on the first message
        const futureTime = new Date(lead.scheduledMessage.createdAt);
        futureTime.setMinutes(futureTime.getMinutes() + firstMessage.minutes);

        const t_scheduledMessage: BotMessage = {
          event_type: "SCHEDULED_MESSAGE",
          user_type: "BIZ",
          time_created: futureTime.toISOString(),
          event_content: {
            text: firstMessage.text
          },
          user_display_name: "Scheduled Message"
        };

        combinedMessages.push(t_scheduledMessage);
      }
    }

    return combinedMessages;
  }
}
