import {Component, OnInit} from "@angular/core";
import {FormBuilder, FormGroup, Validators, AbstractControl, ValidationErrors} from "@angular/forms";
import {MatDialogRef} from "@angular/material/dialog";
import {UserService} from "src/app/Services/user.service";
import {isPossiblePhoneNumber, parsePhoneNumber} from "libphonenumber-js";
import {Regexes} from "../../../../../server/src/helpers/regexes";

export interface RequestDemoInput {
  fullName: string;
  email: string;
  phone: string;
}

@Component({
  selector: "app-demo-dialog",
  templateUrl: "./demo-dialog.component.html",
  styleUrls: ["./demo-dialog.component.scss"]
})
export class DemoDialogComponent implements OnInit {
  public demoForm: FormGroup;
  public submitting = false;

  constructor(
    private fb: FormBuilder,
    private userService: UserService,
    public dialogRef: MatDialogRef<DemoDialogComponent>
  ) {}

  ngOnInit(): void {
    this.demoForm = this.fb.group({
      fullName: ["", Validators.required],
      email: ["", [Validators.required, Validators.pattern(Regexes.emailRegex)]],
      phone: ["", [Validators.required, this.isValidPhoneValidator]]
    });
  }

  public async onSubmit(): Promise<void> {
    if (this.demoForm.invalid) {
      return;
    }
    try {
      this.submitting = true;
      await this.requestDemo();
    } finally {
      this.submitting = false;
    }

    this.dialogRef.close(true);
  }

  private async requestDemo(): Promise<void> {
    const input: RequestDemoInput = {
      ...this.demoForm.value
    };
    input.phone = this.formatPhoneNumber(input.phone);
    await this.userService.requestDemo(input);
  }

  private formatPhoneNumber(phone: string): string {
    try {
      return parsePhoneNumber(phone, "US").formatInternational();
    } catch (error) {
      return phone;
    }
  }

  private isValidPhoneValidator(control: AbstractControl): ValidationErrors | null {
    try {
      return isPossiblePhoneNumber(control.value, "US") ? null : {invalidPhone: true};
    } catch {
      return {invalidPhone: true};
    }
  }
}
