import {Component, ElementRef, Input, ViewChild} from "@angular/core";
import {getIntervalBetweenTwoDates} from "../../../../../../server/src/helpers/time-helper";
import {DateTime} from "luxon";
import {OperationConfigComponent} from "../../../components/operation-config/operation-config.component";

@Component({
  selector: "app-counter",
  standalone: true,
  imports: [],
  templateUrl: "./counter.component.html",
  styleUrl: "./counter.component.scss"
})
export class CounterComponent {
  @Input() text: string;
  @Input() number: number;
  @Input() numberPostfix: string;
  @Input() timeMs: number;

  num = 0;
  private counterInterval: NodeJS.Timeout;
  private timeStarted: DateTime;
  private observer: IntersectionObserver;
  private threshold = 0.3;

  constructor(private elRef: ElementRef) {}

  ngOnInit() {
    this.num = this.number;
  }

  startCounter() {
    this.timeStarted = DateTime.now();
    this.counterInterval = setInterval(() => {
      const millisecondsPassed = getIntervalBetweenTwoDates(this.timeStarted, DateTime.now()).length("milliseconds");
      const relative = millisecondsPassed / this.timeMs;
      if (relative > 1) {
        this.num = this.number;
        clearInterval(this.counterInterval);
        return;
      }
      this.num = Math.round(relative * this.number);
    });
  }

  ngAfterViewInit() {
    this.observer = new IntersectionObserver(this.onIntersection, {
      root: null, // default is the viewport
      threshold: this.threshold // percentage of target's visible area. Triggers "onIntersection"
    });
    this.observer.observe(this.elRef.nativeElement);
  }

  onIntersection = (entries, opts) => {
    console.log(entries[0].intersectionRatio);
    if (entries[0].intersectionRatio > this.threshold) {
      this.startCounter();
      this.observer.disconnect();
    }
  };

  ngOnDestroy() {
    this.observer.disconnect();
  }
}
