import {Component, Input} from "@angular/core";
import {OperationConfigType} from "../../../../../../../server/src/db/classes/operation-config.type";
import {DataService} from "src/app/Services/data.service";
import {IntegrationService} from "src/app/Services/integration.service";
import {
  WorkizIntegration,
  NotificationIntegrationType
} from "../../../../../../../server/src/db/classes/notification-integration.type";
import {SourceConfigDialogData} from "src/app/dialogs/source-config-dialog/source-config-dialog.component";

@Component({
  selector: 'app-workiz-integration-config',
  templateUrl: './workiz-integration-config.component.html',
  styleUrls: ['./workiz-integration-config.component.scss']
})
export class WorkizIntegrationConfigComponent {
  @Input() integration: WorkizIntegration;
  @Input() sourceConfig: SourceConfigDialogData;

  public isConfirmDelete: boolean = false;
  public newAccessToken = "";
  public newApiSecret = "";
  public isLodaing = false;
  constructor(
    private dataService: DataService,
    private integrationService: IntegrationService
  ) {}

  private get operationConfigId(): string {
    switch (this.sourceConfig.type) {
      case OperationConfigType.Location: {
        return this.dataService.allSourcesHashedById$.value[this.sourceConfig.sourceId].locationConfig$;
      }
      case OperationConfigType.Business: {
        return this.sourceConfig.businessId;
      }
      default:
        throw new Error("Unsupported operation type");
    }
  }

  public async toggleEnable(newStatus: boolean): Promise<void> {
    const status = await this.integrationService.toggleEnable(
      this.operationConfigId,
      NotificationIntegrationType.Workiz,
      this.sourceConfig.type,
      newStatus
    );

    this.integration.enabled = status;
  }

  public confirmDelete(): void {
    this.isConfirmDelete = true;
  }

  public cancelDelete(): void {
    this.isConfirmDelete = false;
  }

  public async deleteAcessToken(): Promise<void> {
    const result = await this.integrationService.removeWorkizAccessToken(this.operationConfigId);

    if (result) {
      this.integration.accessToken = null;

      this.toggleEnable(false);
    } else {
      this.cancelDelete();
    }
  }

  public async addAccessToken(): Promise<void> {
    this.isLodaing = true;
    try {
      const result = await this.integrationService.manageWorkizAccessToken(
        this.operationConfigId,
        this.sourceConfig.type,
        this.newAccessToken,
        this.newApiSecret
      );
      if (result) {
        this.integration.accessToken = result.accessToken;
        this.integration.apiSecret = result.apiSecret;
      }
    } catch (e) {
    }
    this.isLodaing = false;
  }
}

