<mat-card style="width: 500px">
  <mat-card-title>REDO LAST X MINUTES OF LEADS</mat-card-title>
  <mat-card-subtitle>
    <div>
      <mat-form-field>
        <mat-label>Minutes</mat-label>
        <input type="number" autofocus matInput [(ngModel)]="minutes" />
      </mat-form-field>
      <button mat-button mat-raised-button (click)="redoLeads()">REDO!</button>
    </div>
  </mat-card-subtitle>
</mat-card>

<br /><br /><br />
<mat-form-field>
  <mat-label>Specific _id of business</mat-label>
  <input matInput [(ngModel)]="billingId" />
</mat-form-field>
<button mat-button mat-raised-button (click)="syncBilling(billingId)">Sync Billing</button>
<br /><br /><br />
<button mat-button mat-raised-button (click)="syncYelpWebhookSources()">Sync Yelp Webhook Sources</button>
<br /><br /><br />
<button mat-button mat-raised-button (click)="fakeAccepted()">Fake Accepted from Yelp</button>
<br /><br /><br />
<button mat-button mat-raised-button (click)="takeSnapshot()">TakeSnapshot</button>
<br /><br /><br />
<button mat-button mat-raised-button (click)="followUps()">FollowUps</button>
<br /><br /><br />
<button mat-button mat-raised-button (click)="empty1()">Empty1</button>
<br /><br /><br />
<button mat-button mat-raised-button (click)="fillTimezone()" [disabled]="isLoading">
  Fill operation config timezone: {{ filledTimezones }}
</button>
