import {Injectable} from "@angular/core";
import {ConnectionService} from "./connection.service";
import {OperationConfigType} from "../../../../server/src/db/classes/operation-config.type";
import {
  HubspotIntegration,
  NotificationIntegrationType,
  PipeDriveIntegration,
  SmsIntegration,
  SmsIntegrationPhone,
  WorkizIntegration
} from "../../../../server/src/db/classes/notification-integration.type";
import {PhoneVerification} from "../../../../server/src/db/classes/phone-verification";

@Injectable({
  providedIn: "root"
})
export class IntegrationService {
  constructor(private connectionService: ConnectionService) {}

  async sendVerifyPhoneSms(number: string, operationConfigId: string): Promise<PhoneVerification> {
    return await this.connectionService.post("/operation-config/integration/sms/sendVerifyPhoneSms", {
      number,
      operationConfigId
    });
  }

  async addVerifiedPhone(
    id: string,
    phone: SmsIntegrationPhone,
    code: number,
    type: OperationConfigType
  ): Promise<SmsIntegration> {
    return await this.connectionService.post("/operation-config/integration/sms/addVerifiedPhone", {
      id,
      phone,
      code,
      type
    });
  }

  async generateCodeByOperationConfigId(operationConfigId: string, type: OperationConfigType): Promise<{code: string}> {
    return await this.connectionService.post("yelpLocations/generateWhatsappByOperationConfigId", {
      operationConfigId,
      type
    });
  }

  async toggleEnable(
    id: string,
    integrationType: NotificationIntegrationType,
    operationConfigType: OperationConfigType,
    newEnabledStatus: boolean
  ): Promise<boolean> {
    return await this.connectionService.post("/operation-config/integration/toogle", {
      id,
      integrationType,
      operationConfigType,
      newEnabledStatus
    });
  }

  async removePhoneNumber(id: string, phone: SmsIntegrationPhone): Promise<boolean> {
    return await this.connectionService.post("/operation-config/integration/sms/removePhoneNumber", {
      id,
      phone
    });
  }

  async manageHubspotAccessToken(
    id: string,
    type: OperationConfigType,
    accessToken: string
  ): Promise<HubspotIntegration> {
    return await this.connectionService.post("/operation-config/integration/hubpost/manageAccessToken", {
      id,
      type,
      accessToken
    });
  }

  async removeHubspotAccessToken(id: string): Promise<boolean> {
    return await this.connectionService.post("/operation-config/integration/hubpost/removeAccessToken", {
      id
    });
  }

  async managePipeDriveAccessToken(
    id: string,
    type: OperationConfigType,
    apiToken: string
  ): Promise<PipeDriveIntegration> {
    return await this.connectionService.post("/operation-config/integration/pipeDrive/manageAccessToken", {
      id,
      type,
      apiToken
    });
  }

  async removePipeDriveAccessToken(id: string): Promise<boolean> {
    return await this.connectionService.post("/operation-config/integration/pipeDrive/removeAccessToken", {
      id
    });
  }

  async manageWorkizAccessToken(
    id: string,
    type: OperationConfigType,
    apiToken: string,
    apiSecret: string
  ): Promise<WorkizIntegration> {
    return await this.connectionService.post("/operation-config/integration/workiz/manageAccessToken", {
      id,
      type,
      apiToken,
      apiSecret
    });
  }

  async removeWorkizAccessToken(id: string): Promise<boolean> {
    return await this.connectionService.post("/operation-config/integration/workiz/removeAccessToken", {
      id
    });
  }

  async disconnectSlack(id: string): Promise<boolean> {
    return await this.connectionService.post("/operation-config/integration/slack/disconnect", {
      id
    });
  }

  async getSlackOAuthUrl(
    id: string,
    type: OperationConfigType,
): Promise<{ authUrl: string}> {
    return await this.connectionService.post("/operation-config/integration/slack/getOAuthUrl", {
      id,
      type,
    });
  }

  async getSlackChannels(
    token: string,
): Promise<{ id: string; name: string }[]> {
    return await this.connectionService.post("/operation-config/integration/slack/getSlackChannels", {
      token,
    });
  }

  async manageSlackChannelId(
    id: string,
    channelId: string,
    token: string,
): Promise<boolean> {
    return await this.connectionService.post("/operation-config/integration/slack/manageSlackChannelId", {
      id, channelId, token
    });
  }
}
