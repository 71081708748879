import {Component} from "@angular/core";
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {GoogleTagManagerService} from "angular-google-tag-manager";
import {filter, Subscription} from "rxjs";
import {MatDialog} from "@angular/material/dialog";

@UntilDestroy()
@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"]
})
export class AppComponent {
  private dialogSub: Subscription;

  constructor(
    private router: Router,
    private matDialog: MatDialog,
    private googleTagManagerService: GoogleTagManagerService,
    private activatedRoute: ActivatedRoute
  ) {
    // @ts-ignore: private option not yet exposed for public use
    router.canceledNavigationResolution = "computed";
    if (navigator.userAgent.indexOf("Edg/") != -1) {
      document.body.classList.add("edge-browser");
    }
  }

  ngOnInit() {
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .pipe(untilDestroyed(this))
      .subscribe((item: NavigationEnd) => {
        const gtmTag = {
          event: "page",
          pageName: item.url
        };
        this.googleTagManagerService.pushTag(gtmTag);
      });

    this.activatedRoute.queryParams.pipe(untilDestroyed(this)).subscribe(async params => {
      console.log(params);
      if (Object.keys(params).length > 0) {
        localStorage.setItem("origin", JSON.stringify(params));

        await this.router.navigate([], {
          queryParams: {}
        });
      }
    });

    this.signUpForDialogChanges();
  }

  private signUpForDialogChanges() {
    this.dialogSub = this.matDialog.afterOpened.pipe(untilDestroyed(this)).subscribe(() => {
      document.body.classList.add("dialogOpen");
    });

    this.dialogSub.add(
      this.matDialog.afterAllClosed.pipe(untilDestroyed(this)).subscribe(() => {
        document.body.classList.remove("dialogOpen");
      })
    );
  }
}
