import {Injectable} from "@angular/core";
import {ConnectionService} from "./connection.service";
import {User, UserSetting} from "../../../../server/src/db/classes/user";
import {RequestDemoInput} from "../dialogs/demo-dialog/demo-dialog.component";
import {
  UserSettingScheduledMessages,
  UserSettingsPredefinedMessages
} from "../../../../server/src/db/classes/user.interfaces";

@Injectable({
  providedIn: "root"
})
export class UserService {
  constructor(private connectionService: ConnectionService) {}

  public async getUserSettings(): Promise<UserSetting | null> {
    return await this.connectionService.post("user/settings");
  }

  public async updateUserSettings(
    predefinedMessages?: UserSettingsPredefinedMessages | null,
    scheduledMessages?: UserSettingScheduledMessages
  ): Promise<User> {
    return await this.connectionService.post("user/updateSettings", {predefinedMessages, scheduledMessages});
  }

  public async requestDemo(param: RequestDemoInput): Promise<boolean> {
    return await this.connectionService.post("user/requestDemo", {...param});
  }
}
