import {Component, Input} from "@angular/core";
import {allSourcesGroup} from "../../../components/location-search/location-search.component";
import {NgForOf} from "@angular/common";

@Component({
  selector: "marker",
  standalone: true,
  imports: [NgForOf],
  templateUrl: "./marker.component.html",
  styleUrl: "./marker.component.scss"
})
export class MarkerComponent {
  words = [];

  @Input() set text(value: string) {
    this.words = value.split(" ");
  }
}
