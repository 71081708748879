<div class="workiz-wrapper">
  <div *ngIf="integration?.accessToken" class="workiz-wrapper__header">
    <h3>Workiz API Key</h3>

    <mat-slide-toggle
      class="example-margin"
      color="primary"
      (change)="toggleEnable($event.checked)"
      [(ngModel)]="integration.enabled"
      [disabled]="!integration?.accessToken">
      <strong>Enabled</strong>
    </mat-slide-toggle>
  </div>

  <mat-accordion *ngIf="integration.accessToken">
    <mat-expansion-panel [expanded]="false">
      <mat-expansion-panel-header>
        <mat-panel-title>{{ integration.accessToken }} </mat-panel-title>
      </mat-expansion-panel-header>
      <mat-action-row *ngIf="!isConfirmDelete">
        <button mat-button color="warn" (click)="confirmDelete()">
          <mat-icon>delete</mat-icon>
        </button>
      </mat-action-row>
      <mat-action-row *ngIf="isConfirmDelete">
        <span class="confirm">Are you sure?</span>
        <button mat-button color="primary" (click)="deleteAcessToken()">Yes</button>
        <button mat-button (click)="cancelDelete()">No</button>
      </mat-action-row>
    </mat-expansion-panel>
  </mat-accordion>

  <div *ngIf="!integration.accessToken">
    <h3 style="margin-top: 20px">Add new API Credentials</h3>
    <ol>
      <li>
        Navigate to your Workiz account:
        <a href="https://app.workiz.com/root/marketplace?name=developer"
          >https://app.workiz.com/root/marketplace?name=developer</a
        >
      </li>
      <li>Enable Developer API</li>
      <li>Go to Developer API Settings</li>
      <li>Generate API Credentials</li>
      <li>Copy your API token and secret</li>
    </ol>

    <mat-form-field appearance="fill">
      <mat-label>Enter your API TOKEN</mat-label>
      <input matInput [(ngModel)]="newAccessToken" />
    </mat-form-field>

    <mat-form-field appearance="fill">
      <mat-label>Enter your API SECRET</mat-label>
      <input matInput [(ngModel)]="newApiSecret" />
    </mat-form-field>

    <button mat-raised-button color="primary" (click)="addAccessToken()" [disabled]="isLodaing">
      Add API Credentials
    </button>
  </div>
</div>
