import {
  GoogleLoginProvider,
  GoogleSigninButtonModule,
  SocialAuthServiceConfig,
  SocialLoginModule
} from "@abacritt/angularx-social-login";
import {A11yModule} from "@angular/cdk/a11y";
import {ClipboardModule} from "@angular/cdk/clipboard";
import {CdkDrag, CdkDragHandle, CdkDropList} from "@angular/cdk/drag-drop";
import {provideHttpClient, withInterceptorsFromDi} from "@angular/common/http";
import {NgModule} from "@angular/core";
import {AngularFireModule} from "@angular/fire/compat";
import {AngularFirestoreModule} from "@angular/fire/compat/firestore";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatAutocompleteModule} from "@angular/material/autocomplete";
import {MatButtonModule} from "@angular/material/button";
import {MatCardModule} from "@angular/material/card";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {MatNativeDateModule} from "@angular/material/core";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MatDialogModule} from "@angular/material/dialog";
import {MatIconModule} from "@angular/material/icon";
import {MatBadgeModule} from "@angular/material/badge";
import {MatInputModule} from "@angular/material/input";
import {MatListModule} from "@angular/material/list";
import {MatMenuModule} from "@angular/material/menu";
import {MatButtonToggleModule} from "@angular/material/button-toggle";
import {MatPaginatorModule} from "@angular/material/paginator";
import {MatProgressBarModule} from "@angular/material/progress-bar";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {MatSelectModule} from "@angular/material/select";
import {MatSidenavModule} from "@angular/material/sidenav";
import {MatSlideToggleModule} from "@angular/material/slide-toggle";
import {MatSnackBarModule} from "@angular/material/snack-bar";
import {MatSortModule} from "@angular/material/sort";
import {MatTableModule} from "@angular/material/table";
import {MatTabsModule} from "@angular/material/tabs";
import {MatToolbarModule} from "@angular/material/toolbar";
import {BrowserModule} from "@angular/platform-browser";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {LeafletModule} from "@asymmetrik/ngx-leaflet";
import {NgSelectModule} from "@ng-select/ng-select";
import {LuxonModule} from "luxon-angular";
import {ComponentsModule} from "../components/components.module";
import {environment} from "../environments/environment";

import {AppRoutingModule} from "./app-routing.module";
import {AppComponent} from "./app.component";
import {LocationSearchComponent} from "./components/location-search/location-search.component";
import {CodeConfirmComponent} from "./components/main-login/code-confirm/code-confirm.component";
import {LoginComponent} from "./components/main-login/login/login.component";
import {MainLoginComponent} from "./components/main-login/main-login.component";
import {SignUpComponent} from "./components/main-login/signup/sign-up.component";
import {MainComponent} from "./components/main/main.component";
import {MenuComponent} from "./components/menu/menu.component";
import {SourceConfigDialogComponent} from "./dialogs/source-config-dialog/source-config-dialog.component";
import {EntityAccountsDialogComponent} from "./dialogs/entity-permissions-dialog/entity-accounts-dialog.component";
import {MapDialogComponent} from "./dialogs/map-dialog/map-dialog.component";
import {YelpInviteDialogComponent} from "./dialogs/yelp-invite-dialog/yelp-invite-dialog.component";
import {SourceDialogComponent} from "./dialogs/source-dialog/source-dialog.component";

import {AcceptedComponent} from "./Pages/accepted/accepted.component";
import {LeadsComponent} from "./Pages/leads/leads.component";
import {SourcesComponent} from "./Pages/sources/sources.component";
import {ReportsComponent} from "./Pages/reports/reports.component";
import {SuperAdminComponent} from "./Pages/superAdmin/superAdmin.component";
import {SourceTokenComponent} from "./Pages/source-token/source-token.component";
import {ObjectHasKeysPipe} from "./pipes/object-has-keys.pipe";
import {LastTextMessagePipe} from "./pipes/last-text-message.pipe";
import {LeadMessagesDialogComponent} from "./dialogs/lead-messages-dialog/lead-messages-dialog.component";

import {MatChipsModule} from "@angular/material/chips";
import {NgChartsModule} from "ng2-charts";
import {BusinessPickerDialogComponent} from "./dialogs/business-picker-dialog/business-picker-dialog.component";
import {MatTreeModule} from "@angular/material/tree";
import {NzInputDebounceDirective} from "./utils/directives/nz-input-debounce.directive";
import {ConfirmDialogComponent} from "./dialogs/confirm-dialog/confirm-dialog.component";
import {SourceTokenRefreshResultsDialogComponent} from "./dialogs/source-token-refresh-results-dialog/source-token-refresh-results-dialog.component";
import {NzFuncPipe} from "./pipes/func-pipe";
import {BusinessDialogComponent} from "./dialogs/business-dialog/business-dialog.component";
import {NgJsonEditorModule} from "ang-jsoneditor";
import {ReportsGraphComponent} from "./Pages/graph/graph-reports.component";
import {PrivacyComponent} from "./compliance/privacy/privacy.component";
import {TermOfServiceComponent} from "./compliance/term-of-service/term-of-service.component";
import {SourceTokenDialogComponent} from "./dialogs/source-token-add-dialog/source-token-dialog.component";
import {MarketingModule} from "./marketing/marketing.module";
import {MatExpansionModule} from "@angular/material/expansion";
import {MatTooltipModule} from "@angular/material/tooltip";
import {ConfirmRevokeTokenComponent} from "./Pages/confirm-revoke-token/confirm-revoke-token.component";
import {OperationConfigComponent} from "./components/operation-config/operation-config.component";
import {OperationConfigFollowUpsComponent} from "./components/operation-config/operation-config-follow-ups/operation-config-follow-ups.component";
import {OperationConfigWelcomeMessageComponent} from "./components/operation-config/operation-config-welcome-message/operation-config-welcome-message.component";
import {IntegrationsComponent} from "./components/operation-config/integrations/integrations.component";
import {WhatsAppIntegrationConfigComponent} from "./components/operation-config/integrations/whats-app-integration-config/whats-app-integration-config.component";
import {SmsIntegrationConfigComponent} from "./components/operation-config/integrations/sms-integration-config/sms-integration-config.component";
import {OperationConfigCustomerPhoneFoundComponent} from "./components/operation-config/operation-config-customer-phone-found/operation-config-customer-phone-found.component";
import {OperationConfigWelcomeMessageComponentAi} from "./components/operation-config/operation-config-welcome-message-ai/operation-config-welcome-message-ai.component";
import {HubspotIntegrationConfigComponent} from "./components/operation-config/integrations/hubspot-integration-config/hubspot-integration-config.component";
import {PipeDriveIntegrationConfigComponent} from "./components/operation-config/integrations/pipe-drive-integration-config/pipe-drive-integration-config.component";
import {LoginAsComponent} from "./Pages/superAdmin/login-as/login-as.component";
import {PhonePipe} from "./pipes/phone-pipe";
import {ActivityLogComponent} from "./Pages/activityLog/activity-log.component";
import {UserSettingsDialogComponent} from "./dialogs/user-settings-dialog/user-settings-dialog.component";
import {TimeAgoPipe} from "./pipes/time-ago-pipe";
import {DemoDialogComponent} from "./dialogs/demo-dialog/demo-dialog.component";
import {LeadMessagesPipe} from "./pipes/lead-messages.pipe";
import {UserSendScheduledMessageDialogComponent} from "./dialogs/user-send-scheduled-message-dialog/user-send-scheduled-message-dialog.component";
import {ScheduledMessagesDialogComponent} from "./dialogs/scheduled-messages-follow-ups-dialog/scheduled-messages-dialog.component";
import { WorkizIntegrationConfigComponent } from "./components/operation-config/integrations/workiz-integration-config/workiz-integration-config.component";
import { SlackIntegrationConfigComponent } from "./components/operation-config/integrations/slack-integration-config/slack-integration-config.component";

@NgModule({
  declarations: [
    AppComponent,
    PrivacyComponent,
    TermOfServiceComponent,
    MainLoginComponent,
    LoginComponent,
    MainComponent,
    SignUpComponent,
    CodeConfirmComponent,
    SourcesComponent,
    ReportsComponent,
    ReportsGraphComponent,
    MenuComponent,
    OperationConfigComponent,
    OperationConfigFollowUpsComponent,
    OperationConfigWelcomeMessageComponent,
    OperationConfigWelcomeMessageComponentAi,
    OperationConfigCustomerPhoneFoundComponent,
    WhatsAppIntegrationConfigComponent,
    SmsIntegrationConfigComponent,
    HubspotIntegrationConfigComponent,
    PipeDriveIntegrationConfigComponent,
    WorkizIntegrationConfigComponent,
    SlackIntegrationConfigComponent,
    IntegrationsComponent,
    SourceTokenComponent,
    NzFuncPipe,
    SuperAdminComponent,
    LoginAsComponent,
    // Dialogs Start
    ConfirmDialogComponent,
    BusinessDialogComponent,
    BusinessPickerDialogComponent,
    MapDialogComponent,
    SourceTokenRefreshResultsDialogComponent,
    SourceTokenDialogComponent,
    EntityAccountsDialogComponent,
    AcceptedComponent,
    ConfirmRevokeTokenComponent,
    SourceDialogComponent,
    YelpInviteDialogComponent,
    LeadMessagesDialogComponent,
    SourceConfigDialogComponent,
    UserSettingsDialogComponent,
    DemoDialogComponent,
    ScheduledMessagesDialogComponent,
    UserSendScheduledMessageDialogComponent,
    // Dialogs End
    LeadsComponent,
    ObjectHasKeysPipe,
    LastTextMessagePipe,
    LeadMessagesPipe,
    ActivityLogComponent,
    LocationSearchComponent,
    NzInputDebounceDirective
  ],
  exports: [],
  bootstrap: [AppComponent],
  imports: [
    AngularFireModule.initializeApp(environment.firebase),
    NgJsonEditorModule,
    MarketingModule,
    AngularFirestoreModule,
    MatDatepickerModule,
    MatNativeDateModule,
    SocialLoginModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatButtonModule,
    MatToolbarModule,
    MatIconModule,
    MatSidenavModule,
    MatDialogModule,
    MatListModule,
    MatMenuModule,
    MatInputModule,
    LuxonModule,
    MatSnackBarModule,
    FormsModule,
    MatCardModule,
    MatSelectModule,
    MatCheckboxModule,
    MatSlideToggleModule,
    MatTabsModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    ClipboardModule,
    CdkDropList,
    CdkDrag,
    NgChartsModule,
    CdkDragHandle,
    GoogleSigninButtonModule,
    MatTableModule,
    NgSelectModule,
    MatAutocompleteModule,
    ReactiveFormsModule,
    MatPaginatorModule,
    MatSortModule,
    LeafletModule,
    ComponentsModule,
    A11yModule,
    MatDatepickerModule,
    MatChipsModule,
    MatTreeModule,
    MatExpansionModule,
    MatBadgeModule,
    MatTooltipModule,
    MatButtonToggleModule,
    PhonePipe,
    TimeAgoPipe
  ],
  providers: [
    {provide: "googleTagManagerId", useValue: "GTM-M65DQCND"},
    {
      provide: "SocialAuthServiceConfig",
      useValue: {
        autoLogin: true,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
              "70803016125-4sovfr8kccklv6b7boha1urgap58d3cn.apps.googleusercontent.com",
              {oneTapEnabled: false}
            )
          }
        ],
        onError: err => {
          console.error(err);
        }
      } as SocialAuthServiceConfig
    },
    provideHttpClient(withInterceptorsFromDi())
  ]
})
export class AppModule {}
