import {Component} from "@angular/core";
import {cloneDeep} from "lodash";
import {ConnectionService} from "../../Services/connection.service";
import {SessionService} from "../../Services/session.service";
import {UntilDestroy} from "@ngneat/until-destroy";

@UntilDestroy()
@Component({
  selector: "app-super-admin",
  templateUrl: "./superAdmin.component.html",
  styleUrls: ["./superAdmin.component.scss"]
})
export class SuperAdminComponent {
  public isLoading = false;

  public filledTimezones = 0;

  minutes = 60;

  whatsappMessage = "";

  constructor(
    public sessionService: SessionService,
    private connectionService: ConnectionService
  ) {}

  redoLeads() {
    this.connectionService.post("superAdmin/redoLeads", {
      minutes: this.minutes
    });
  }

  billingId;
  syncBilling(id) {
    const body = id ? {id} : {};
    this.connectionService.post("superAdmin/syncBilling", body);
  }

  takeSnapshot() {
    this.connectionService.post("superAdmin/takeSnapshot");
  }

  syncYelpWebhookSources() {
    this.connectionService.post("superAdmin/syncYelpWebhooks");
  }

  empty1() {
    this.connectionService.post("superAdmin/empty1");
  }

  async fillTimezone() {
    this.isLoading = true;
    const result = await this.connectionService.post("operation-config/superAdmin/fillTimezone");
    this.filledTimezones = result.updatedCount;
    this.isLoading = false;
  }

  fakeAccepted() {
    this.connectionService.post(
      "accepted?response_type=code&scope=leads+r2r_get_businesses+r2r_business_owner&state=U2FsdGVkX18J9e/wgndQNHKSoL4K1qIedgyBVqW9aDXzhRu7rXo3SQ6AwocSg0UhxdZQJbccXCXOjwIxdyf8VQ==",
      "",
      "get"
    );
  }

  followUps() {
    this.connectionService.post("handle_followups", "");
  }
}
