import {Component, ViewChild} from "@angular/core";
import {FormControl} from "@angular/forms";
import {SuperAdminService} from "src/app/Services/super-admin.service";
import {BehaviorSubject, Observable} from "rxjs";
import {SUPER_ADMIN_SESSION_KEY, SessionService} from "src/app/Services/session.service";
import {StorageService} from "src/app/Services/storage.service";
import {LoginService} from "src/app/Services/login.service";
import {MatAutocompleteTrigger} from "@angular/material/autocomplete";

export interface MinimalUser {
  _id: string;
  email: string;
  name?: string;
}

@Component({
  selector: "app-login-as",
  templateUrl: "./login-as.component.html",
  styleUrls: ["./login-as.component.scss"]
})
export class LoginAsComponent {
  @ViewChild(MatAutocompleteTrigger) autocompleteTrigger: MatAutocompleteTrigger;

  public userControl = new FormControl();
  public filteredUsers: Observable<MinimalUser[]>;
  public selectedUser: MinimalUser | null = null;
  public isLoading = false;
  private users: MinimalUser[] = [];
  private usersSubject = new BehaviorSubject<MinimalUser[]>([]);

  constructor(
    private superAdminService: SuperAdminService,
    public sessionService: SessionService,
    private loginService: LoginService
  ) {
    this.filteredUsers = this.usersSubject.asObservable();
  }

  public onFormFieldClicked(): void {
    if (this.users.length === 0) {
      this.loadUsers();
    } else {
      this.openAutocomplete();
    }
  }

  private loadUsers(): void {
    this.isLoading = true;
    this.superAdminService.getAllUserWithId().then(
      ({allUsers}) => {
        this.users = allUsers;
        this.usersSubject.next(allUsers);
        this.isLoading = false;
        this.openAutocomplete();
        this.filterUsers(this.userControl.value)
      },
      error => {
        console.error("Failed to fetch users:", error);
        this.isLoading = false;
      }
    );
  }

  private openAutocomplete(): void {
    setTimeout(() => {
      this.autocompleteTrigger.openPanel();
    });
  }

  public filterUsers(value: string): void {
    const filterValue = value.toLowerCase();
    const filteredUsers = this.users.filter(user => user.email.toLowerCase().includes(filterValue));
    this.usersSubject.next(filteredUsers);
  }

  public async onUserSelected(event: any): Promise<void> {
    this.selectedUser = event.option.value;
    const {newSession} = await this.superAdminService.loginAs(this.selectedUser._id);
    this.saveSuperAdminSession();
    this.sessionService.session = newSession;
    location.reload();
  }

  public logoutSuperAdmin(): void {
    this.selectedUser = null;
    this.userControl.setValue("");
    this.loginService.logoutSession();
    this.restoreSuperAdminSession();
    location.reload();
  }

  private saveSuperAdminSession() {
    const currentSession = this.sessionService.session;
    if (currentSession) {
      StorageService.setItem(SUPER_ADMIN_SESSION_KEY, currentSession);
    }
  }

  private restoreSuperAdminSession() {
    const superAdminSession = this.sessionService.prevSessionSuperAdmin;
    if (superAdminSession) {
      this.sessionService.session = superAdminSession;
      StorageService.removeItem(SUPER_ADMIN_SESSION_KEY);
    }
  }

  public get hasSuperAdminRole(): boolean {
    const superAdminSession = this.sessionService.prevSessionSuperAdmin;
    return this.currentIsSuperAdmin || (superAdminSession && superAdminSession.role === "superAdmin");
  }

  public get currentIsSuperAdmin(): boolean {
    const currentSession = this.sessionService.session;
    return currentSession && currentSession.role === "superAdmin";
  }

  public displayUser(user: MinimalUser): string {
    return user && user.email ? user.email : "";
  }
}
